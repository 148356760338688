<template>
  <div>
     <div>
       <img src="../assets/beaconimg/banner.png" width="100%">
     </div>

      <div class="advantage">
        <p>核心优势</p>
          <div class="kernel">
          <img src="../assets/beaconimg/beimgone.png" >
           <img src="../assets/beaconimg/beimgtwo.png" >
           <img src="../assets/beaconimg/beimgthere.png" >
           <img src="../assets/beaconimg/beimgfour.png" >
           <img src="../assets/beaconimg/beimgfive.png"  >
           <img src="../assets/beaconimg/beimgsix.png" >
          </div>
      </div>

      <div class="middleground">
        <p>烽台[中台+]架构</p>
        <img src="../assets/beaconimg/beimgseven.png" width="100%">
      </div>

      <div class="scene">
      <img src="../assets/beaconimg/beimgeight.png" >
      <img src="../assets/beaconimg/beimgnine.png" >
       <img src="../assets/beaconimg/beimgten.png" >
      </div>

      <div class="initiated">
             <div class="journey">
           <input type="text" value="开起数字化旅程" disabled>
           <bottom>立即体验</bottom>
       </div>
      </div>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.advantage{
  margin-top: 43px;
  text-align: center;
  .kernel{
    width: 1422px;
    margin: 0 auto;
    img{
       width: 685px;
       height: 160px;
    }
  }
  p{
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  padding-bottom: 40px;
  }
  img{
    margin: 0 13px 23px 13px;
  }
}

.middleground{
   position: relative;
   margin-top: 65px;
   p{
  position: absolute;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  left: 43%;
  top: -20px;
   }
}

.scene{
  margin-top: 54px;
  text-align: center;
  img{
    margin: 0 13px 0 13px;
    width: 448px;
  }

}

.initiated{
  display: flex;
 align-items: center;
justify-content: center;
 margin: 89px 0 100px 0;
}
.journey{
  margin-left: 22px;
  position: relative;
  input{
  width: 500px;
height: 72px;
border: 1px solid #FF892E;
opacity: 1;
border-radius: 4px;
font-size: 24px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #000000;
text-align: center;
padding-right: 236px;
  }
  bottom{
    top:0;
    right: 0;
 position: absolute;
height: 72px;
width: 200px;
border-radius: 4px;
font-size: 24px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #FFFFFF;
text-align: center;
background: #FF892E;
padding-top: 21px;
  }
}
</style>
